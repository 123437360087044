import panelRoutes from "@/router/routes/panelRoutes";

export default [
    {
        path: '/',
        name: 'MainLayout',
        component: () => import('../layouts/MainLayout'),
        // redirect: '/fa/panel/dashboard',
        children: [
            {
                path: "calendar",
                name: "calender",
                component: () => import('../views/calendar')
            },
            {
                path: 'assignconsult',
                name: 'paymentProcess',
                component: () => import('../views/indexes/PaymentStatus')
            },
            {
                path: 'process-payment',
                name: 'ProcessPayment',
                component: () => import('../views/indexes/ProcessPayment'),
                meta: {
                    auth: true

                }
            },
            {
                path: 'social-login',
                name: 'socialLogin',
                component: () => import('../views/indexes/SocalLogin')
            },
            {
                path: 'password/reset/:token',
                name: 'ResetPassword',
                component: () => import('../views/indexes/ResetPassword')
            },
            {
                path: ':lang',
                name: 'languageView',
                redirect: "/",
                component: () => import('../layouts/LanguageView'),
                children: [
                    ...panelRoutes,
                    {
                        path: 'login',
                        name: 'login',
                        meta: {
                            guest: true
                        },
                        component: () => import('../views/panel/LoginView')
                    },
                    {
                        path: 'initialize',
                        name: 'TelegramInitialize',
                        // meta: {
                        //     guest: true
                        // },
                        component: () => import('../views/panel/LoginInitializeView')
                    },
                    {
                        path: 'onboarding',
                        name: 'onboarding',
                        component: () => import('../views/indexes/OnboardingRefactor')
                    },
                    {
                        path: 'onboarding_new',
                        name: 'onboarding',
                        component: () => import('../views/indexes/OnboardingRefactor')
                    },
                    // {
                    //     path: 'match',
                    //     name: 'match',
                    //     component: () => import('../views/indexes/match_making')
                    // },
                    {
                        path: "preorder/sign/:orderId",
                        name: 'preorder',
                        component: () => import('../views/indexes/Preorder')
                    },
                    {
                        path: "onboarding-new",
                        name: "onboarding_new",
                        component: () => import('../views/indexes/NewOnboarding'),
                        meta: {
                            auth: true,
                        }
                    },
                    // {
                    //     path: "onboarding-presession",
                    //     name: "onboarding_presession",
                    //     component: () => import('../views/indexes/NewOnboardingPreSession'),
                    //     meta: {
                    //         auth: true,
                    //     }
                    // },
                    {
                        path: 'test',
                        component: () => import('../views/indexes/tests/testLayout'),
                        children: [
                            {
                                path: ':testID',
                                name: 'testStepper',
                                component: () => import('../views/indexes/tests/testStepper')
                            },
                            {
                                path: ':testID/interpretation/:userTestId',
                                name: 'interpretationTest',
                                component: () => import('../views/indexes/tests/testInterpretation')
                            }
                        ]
                    },
                    {
                        path: 'presession',
                        name: 'presession',
                        component: () => import('../views/presession/presession')
                    },
                    {
                        path: 'static-presession',
                        name: 'staticPresession',
                        meta: {
                            auth: true
                        },
                        component: () => import('../views/presession/StatisPreSession')
                    },
                    {
                        path: 'campaign',
                        component: () => import('../views/indexes/campaigns/campaignLayout'),
                        children: [
                            {
                                path: 'turkish',
                                name: "turkish_campaign",
                                component: () => import('../views/indexes/campaigns/TurkishCamaign')
                            }
                        ]
                    },
                    {
                        path: 'login-with-token',
                        name: 'LoginWithToken',
                        component: () => import('../views/LoginWithToken'),
                        meta: {
                            // guest: true
                        },
                    }

                ]
            }
        ]
    },
    {path: "*", redirect: '/fa/login'}
]
