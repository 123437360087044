export default [
    {
        name: "getSessions",
        path: 'user/sessions/search?page=1&perPage=100',
        method: "post",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_SESSIONS',
                key: 'sessions',
                dataPath: 'data.sessions',
                localStoragePersist: false,
                debug: false,
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getSessionsWithOutUpdateStoreForDay",
        path: 'user/sessions/search?page=1&perPage=10',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getSessionsForDay",
        path: 'user/sessions/search?page=:page&perPage=10',
        method: "post",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_SESSIONS',
                key: 'sessions',
                dataPath: 'data.sessions',
                localStoragePersist: false,
                debug: false,
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },

    {
        name: "sendSessionRequest",
        path: 'user/sessions/request',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "storeTome",
        path: 'user/times',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "sendPreSessionRequestAndGetTime",
        path: 'user/sessions/triage-times',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "setTimeForSession",
        path: 'user/sessions/request/:userSession',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getTimesForPreSession",
        path: 'user/sessions/request/:sessionID/times',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "selectTimeForPreSession",
        path: 'user/sessions/triage-request',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getPreSessionPackage",
        path: 'user/sessions/pre-session-package',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
]

// Route::post('', 'UserSessionController@triageSessionRequest');
// Route::post('', 'UserSessionController@setSessionRequestTimes');

// /sessions/request/{userSession}/times,

// Route::post('',