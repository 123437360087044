export default [
    {
        name: "SendSurveyAnswer",
        path: 'user/onboarding-survey',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "insertCycle",
        path: 'user/cycles',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "request-test-analysis",
        path: 'user/request-test-analysis',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "getInvitees",
        path: 'user/invitees',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "processStaticPresession",
        path: 'user/process-static-presession',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "getEventStatus",
        path: 'user/events/:id/status',
        method: "get",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "startPreSessionAiFlow",
        path: 'user/psychepro/start-flow',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },

]
